
@font-face {
  font-family: "GlacialIndifference";
  src: url('./media/fonts/GlacialIndifference-Regular.otf');
}

@font-face {
  font-family: "GlacialIndifference";
  src: url('./media/fonts/GlacialIndifference-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: "Terek";
  src: url('./media/fonts/Terek.otf');
}

@font-face {
  font-family: "Terek";
  src: url('./media/fonts/Terek.otf');
  font-weight: bold;
}